import React, { useState } from "react";
import { useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "./config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';

const Approval = () => {
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState("")
    const [ids, setIds] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [editShow, setEditShow] = useState(false);

    // Wrap the definition of 'abc' in useCallback
    const history = useHistory();
    const abc = useCallback(() => {
        if (typeof localStorage !== 'undefined') {
            const sess = localStorage.getItem('token');
            if (!sess) {
                history.push('/');
            }
        }

        if (typeof localStorage !== 'undefined') {
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: {} };
            const encodedData = btoa(JSON.stringify(dObject));
            const requestData = { "data": encodedData };
            axios
                .post(BASE_URL + "approvals/pending.php", requestData)
                .then(function (data) {
                    console.log(data);
                    if (data.data.success === true) {
                        setTableData(data.data.data.companies);
                        setIsLoading(false);

                    }
                })
                .catch(error => console.error(error));
        }
    }, [setTableData]); // Include router and setTableData in the dependency array

    useEffect(() => {
        abc();
    }, [abc]); // Use 'abc' as the dependency for useEffect

    const handleEdit = (id) => {

        console.log(id)
        setState({
            ...state, cname: id.newCompanyName,
            cat: id.tagId,
            eid: id.id,
            catname: id.tags,
            pname: id.pname,
            citys: id.newCity,
            location: id.newLocation,
            scode: id.newState,
            stateCode: id.userState,
            altphone: id.ualtphone,
            credit: id.credit,
            gst: id.newGst,
            phone: id.uphone, email: id.email,
            checkboxes: id.checkboxes,
            cid: id.companyId
        });
        setEditShow(true)
    }


    const columns = [
        { name: 'Name', selector: (row) => row.newCompanyName, sortable: true },
        { name: 'City', selector: (row) => row.newCity, sortable: true },
        { name: 'State', selector: (row) => row.newState, sortable: true },
        { name: 'GST', selector: (row) => row.newGst, sortable: true },
        { name: 'Location', selector: (row) => row.newLocation, sortable: true },
        { name: 'View', cell: (row) => <img src="assets/images/eye.png" style={{ height: "24px", width: "24px" }} alt="Bin" onClick={() => handleEdit(row)} />, sortable: true },
    ];
    const handlePageChange = (page) => {
        setLoading(true);
        setCurrentPage(page);
    };


    const filteredData = searchText
        ? tableData.filter((item) =>
            item.newCompanyName.toLowerCase().includes(searchText.toLowerCase()) ||
            item.newState.trim().toString().includes(searchText.toString())


        )
        : tableData;



    const paginatedData = filteredData.slice((currentPage - 1) * perPage, currentPage * perPage);

    const handler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const ApproveData = (e) => {
        e.preventDefault();
        if (typeof localStorage !== 'undefined') {
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: { "id": state.eid, "companyId": state.cid } };
            const encodedData = btoa(JSON.stringify(dObject));
            const deleteData = { "data": encodedData }
            axios
                .post(BASE_URL + "approvals/approve.php", deleteData)
                .then(function (data) {
                    if (data.data.success === true) {
                        toast.success("Company Approved Successfully");
                        setEditShow(false)
                        abc();

                    } else {
                        toast.error("Company not Approved");
                        setEditShow(false)

                    }
                }
                )
                .catch(error => console.error(error))

        } else {
            toast.error("Company not Approved");
            setEditShow(false)

        }
    }



    const RejectData = (e) => {
        e.preventDefault();
        if (typeof localStorage !== 'undefined') {
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: { "id": state.eid, "companyId": state.cid, "remarks": "reject" } };
            const encodedData = btoa(JSON.stringify(dObject));
            const deleteData = { "data": encodedData }
            axios
                .post(BASE_URL + "approvals/reject.php", deleteData)
                .then(function (data) {
                    if (data.data.success === true) {
                        toast.success("Company Rejected Successfully");
                        setEditShow(false)

                        abc();
                    } else {
                        toast.error("Company not Rejected");
                        setEditShow(false)

                    }
                }
                )
                .catch(error => console.error(error))
            setEditShow(false)

        }
    }
    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    return (
        <div>
            <div class="main-wrapper">

                <div class="page-wrapper">

                    <div class="page-content">
                        <ToastContainer />
                        <nav class="page-breadcrumb row">
                            <div class="col-md-12">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"> Review Company

                                    </li>
                                    {/* <li class="breadcrumb-item active" aria-current="page">Basic Tables</li> */}
                                </ol>
                            </div>

                        </nav>
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    {/* <DataTable
                                title="Company Approval"
                                columns={columns}
                                data={tableData}
                                pagination
                                paginationServer
                            paginationTotalRows={1}
                            paginationPerPage={12}
                            onChangePage={handlePageChange}
                            progressPending={loading}
                            selectableRows nable row selection if needed
                            selectableRowsHighlight // Highlight selected rows if needed
                            paginationComponentOptions={{
                                rowsPerPageText: 'Rows per page:',
                                rangeSeparatorText: 'of',
                            }}
                            /> */}


                                    < DataTable
                                        columns={columns}
                                        data={paginatedData}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={tableData.length}
                                        paginationPerPage={perPage}
                                        onChangePage={handlePageChange}
                                        //progressPending={loading}
                                        onChangeRowsPerPage={handlePerPageChange}
                                        subHeader
                                        subHeaderComponent={
                                            <div class="col-md-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    class="form-control"
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                            </div>
                                        } />


                                    <div
                                        className={`modal fade bd-example-modal-xl ${editShow ? 'show' : ''}`}
                                        tabIndex="-1"
                                        style={{ display: editShow ? 'block' : 'none', backgroundColor: "#21211f4a" }}
                                        aria-labelledby="myExtraLargeModalLabel"
                                        role={editShow ? "dialog" : ""}
                                        aria-hidden={!editShow}
                                        data-bs-backdrop="static"
                                        aria-modal={editShow ? true : false}
                                    >
                                        <div class="modal-dialog modal-xl" >
                                            <div class="modal-content card">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" style={{ textAlign: "center" }} id="exampleModalLabel">Company Details</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
                                                </div>
                                                <form id="signupForm " className='row' >

                                                    <div class="card-body row">

                                                        <div class="col-lg-6 ">
                                                            <input id="name" class="form-control" name="eid" hidden onChange={handler} value={state.eid} type="text" readOnly />

                                                            <div class="mb-3">
                                                                <label for="name" class="form-label">Name</label>
                                                                <input id="name" class="form-control" name="cname" onChange={handler} value={state.cname} type="text" readOnly />
                                                            </div>


                                                            <div class="mb-3">
                                                                <label for="confirm_password" class="form-label">Location</label>
                                                                <input id="confirm_password" class="form-control" name="location" onChange={handler} value={state.location} type="text" readOnly />
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="email" class="form-label">State</label>
                                                                <input id="email" class="form-control" name="stateName" onChange={handler} value={state.scode} type="text" readOnly />
                                                            </div>
                                                        </div>


                                                        <div class="col-md-6 ">
                                                            <div class="mb-3">
                                                                <label for="email" class="form-label">GST</label>
                                                                <input id="email" class="form-control" name="gst" onChange={handler} value={state.gst} type="text" readOnly />
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="password" class="form-label">City</label>
                                                                <input id="password" class="form-control" name="citys" onChange={handler} value={state.citys} type="text" readOnly />
                                                            </div>
                                                        </div>
                                                        <div class="mb-3">

                                                            <h5 class="modal-title" style={{ textAlign: "center" }} id="exampleModalLabel">Person Details</h5>
                                                        </div>
                                                        <div class="col-lg-6 ">
                                                            <div class="mb-3">
                                                                <label for="name" class="form-label">Person Name</label>
                                                                <input id="name" class="form-control" name="pname" onChange={handler} value={state.pname} type="text" readOnly />
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="confirm_password" class="form-label">Alternate Phone Number</label>
                                                                <input id="confirm_password" class="form-control" name="altphone" onChange={handler} value={state.altphone} type="text" readOnly />
                                                            </div>


                                                        </div>
                                                        <div class="col-lg-6 ">
                                                            <div class="mb-3">
                                                                <label for="password" class="form-label">Phone Number</label>
                                                                <input id="password" class="form-control" name="phone" onChange={handler} value={state.phone} type="text" readOnly />
                                                            </div>

                                                        </div>


                                                        <div class="modal-footer">
                                                            <input class="btn btn-primary" style={{ alignContent: "center" }} type="submit" value="Approve" onClick={(e) => ApproveData(e)} />

                                                            <input class="btn btn-primary" style={{ alignContent: "center" }} type="submit" value="Reject" onClick={(e) => RejectData(e)} />

                                                            <button class="btn btn-danger" type="button" onClick={() => setEditShow(false)} >Close</button>
                                                        </div>

                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Approval;