import React, { useState } from "react";
import { useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "./config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

const Premium = () => {
    const [selectedOption, setSelectedOption] = useState(null);

    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({ eid: "", rank: "", status: "", rid: "" })
    const [ids, setIds] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');    // Wrap the definition of 'abc' in useCallback
    const history = useHistory();
    const [options, setOptions] = useState([])
    const [selectedCate, setSelectedCate] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [company, setCompany] = useState("");
    const [editShow, setEditShow] = useState(false)

    const { eid, rank, status, rid } = state
    const LIST = () => {
        if (typeof localStorage !== 'undefined') {
            // Access localStorage here
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: {} };
            const encodedData = btoa(JSON.stringify(dObject));
            const requestData = { "data": encodedData }
            axios
                .post(BASE_URL + "company/list.php", requestData)
                .then(function (data) {

                    console.log(data.data.data)

                    if (data.data.success == true) {

                        setOptions(data.data.data.companies)
                    }
                })

                .catch(error => console.error(error))
        }
    }



    const abc = () => {
        if (typeof localStorage !== 'undefined') {
            // Access localStorage here
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: {} };
            const encodedData = btoa(JSON.stringify(dObject));
            const requestData = { "data": encodedData }

            axios
                .post(BASE_URL + "recommendations/list.php", requestData)
                .then(function (data) {
                    console.log(data.data)
                    if (data.data.success == true) {
                        setTableData(data.data.data.activeRecommendations)
                        setIsLoading(false)
                    }
                })

                .catch(error => console.error(error))
        }
    }
    // const history = useHistory();
    useEffect(() => {
        const sess = localStorage.getItem('token');
        if (!sess) {
            history.push('/');
        }
        abc();
        LIST();
    }, [1]); // Use 'abc' as the dependency for useEffect

    const handleEdit = (id) => {

        console.log(id)
        setState({
            ...state, eid: id.companyId,

            status: "rank"
        });
        setEditShow(true)
    }


    const handleDelete = (id) => {

        console.log(id)
        setState({
            ...state,
            rid: id.recommendationId,
            status: "delete"
        });
        setEditShow(true)

    }

    const ADD = (e) => {
        console.log(state)
        e.preventDefault();
        if (typeof localStorage !== 'undefined') {
            if (state.status == "rank") {
                const token = localStorage.getItem('token');
                const dObject = { authorization: token, input: { "id": state.eid, "rank": state.rank } };
                const encodedData = btoa(JSON.stringify(dObject));
                const deleteData = { "data": encodedData }
                axios
                    .post(BASE_URL + "recommendations/add_rank.php", deleteData)
                    .then(function (data) {
                        console.log(data)
                        if (data.data.success === true) {
                            // setShowEdit(false)
                            toast.success("Rank Added Successfully")
                            abc();
                            setState({ status: "" })
                            setEditShow(false)

                        } else {
                            toast.error("Rank not added")
                            setState({ status: "" })
                            setEditShow(false)


                        }
                    }
                    )
                    .catch(error => console.error(error))

            } else if (state.status == "delete") {
                const token = localStorage.getItem('token');
                const dObject = { authorization: token, input: { "rid": state.rid, "companyId": state.eid } };
                const encodedData = btoa(JSON.stringify(dObject));
                const deleteData = { "data": encodedData }
                axios
                    .post(BASE_URL + "recommendations/delete.php", deleteData)
                    .then(function (data) {
                        if (data.data.success === true) {
                            toast.success("company deleted successfully")
                            setState({ status: "" })
                            setEditShow(false)

                            abc();
                        } else {
                            toast.error("company Not deleted")
                            setState({ status: "" })
                            setEditShow(false)


                        }
                    }
                    )
            } else {
                // Access localStorage here
                const token = localStorage.getItem('token');
                const dObject = { authorization: token, input: { "companyId": company } };
                const encodedData = btoa(JSON.stringify(dObject));
                const requestData1 = { "data": encodedData }
                axios
                    .post(BASE_URL + "recommendations/create.php", requestData1)
                    .then(function (data) {

                        // console.log(data)
                        if (data.data.success == true) {
                            toast.success("Premium Company added successfully")
                            setState({ status: "" })
                            setEditShow(false)

                            abc()
                        } else {
                            toast.error("Premium Company Not Added")
                            setState({ status: "" })
                            setEditShow(false)

                        }
                    })
                    .catch(error => console.error(error));
            }
        }
    }

    const handleCloseClick = (e) => {
        e.preventDefault();  // Prevent the default form submission behavior
        setState({ status: "" })
        setEditShow(false)

    };

    const columns = [
        { name: 'Name', selector: (row) => row.name, sortable: true },
        { name: 'City', selector: (row) => row.city, sortable: true },
        { name: 'Phone No.', selector: (row) => row.phone, sortable: true },
        { name: 'Start Date', selector: (row) => row.createdOn, sortable: true },
        { name: 'Expiry Date', selector: (row) => row.expiresOn, sortable: true },
        { name: 'Rank', selector: (row) => row.rank, sortable: true },
        { name: 'Edit', cell: (row) => <img src="assets/images/edit.png" style={{ height: "24px", width: "24px" }} alt="Bin" onClick={() => handleEdit(row)} />, sortable: true },
        { name: 'Delete', cell: (row) => <img src="assets/images/bin.png" style={{ height: "24px", width: "24px" }} alt="Bin" onClick={() => handleDelete(row)} />, sortable: true },
    ];

    const handlePageChange = (page) => {
        setLoading(true);
        setCurrentPage(page);
    };
    const paginatedData = tableData.slice((currentPage - 1) * perPage, currentPage * perPage);


    const handleCateChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setCompany(selectedOption.id)
        console.log(selectedOption.id)

        // Add any other logic you want to perform on option change
    };

    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    return (
        <div>
            <div class="main-wrapper">

                <div class="page-wrapper">

                    <div class="page-content">
                        <ToastContainer />

                        <nav class="page-breadcrumb row">
                            <div class="col-md-10">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">Premium Company</li>
                                    {/* <li class="breadcrumb-item active" aria-current="page">Basic Tables</li> */}
                                </ol>
                            </div>
                            <div class="col-md-2">
                                <button type='button' class="btn btn-primary" style={{ float: "right" }} onClick={() => setEditShow(true)}>Add Premium Company</button>
                            </div>
                        </nav>
                        <div class="card">
                            <div class="card-body">

                                <div
                                    className={`modal fade  ${editShow ? 'show' : ''}`}
                                    tabIndex="-1"
                                        style={{ display: editShow ? 'block' : 'none', backgroundColor: "#21211f4a" }}
                                    aria-labelledby="exampleModalLabel"
                                    role={editShow ? "dialog" : ""}
                                    aria-hidden={!editShow}
                                    data-bs-backdrop="static"
                                    aria-modal={editShow ? true : false}
                                >

                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Premium Company</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button> */}
                                            </div>
                                            <div class="modal-body">

                                                {state.status === "rank" ? (
                                                    <>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={state.rank}
                                                                onChange={(e) => setState({ ...state, rank: e.target.value })}
                                                            />
                                                        </div>
                                                    </>
                                                ) : state.status === "delete" ? (
                                                    <>
                                                        <div>
                                                            Are You Sure ?
                                                        </div>
                                                    </>
                                                ) : (
                                                    <><p>Select Company</p>
                                                        <Select
                                                            value={selectedOption}
                                                            onChange={handleCateChange}
                                                            options={options}
                                                            getOptionLabel={(option) => `${option.name} (${option.phone}) ${option.personName || ''}`}
                                                            placeholder="Select Company"
                                                            isSearchable
                                                        />
                                                    </>
                                                )}

                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-danger" onClick={(e) => ADD(e)}>{state.status == "delete" ? "Delete" : "Submit"}</button>
                                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={(e) => handleCloseClick(e)}>Close</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    {/* <DataTable
                                title="Premium Company"
                                columns={columns}
                                data={tableData}
                                pagination
                                paginationServer
                            paginationTotalRows={1}
                            paginationPerPage={12}
                            onChangePage={handlePageChange}
                            progressPending={loading}
                            selectableRows nable row selection if needed
                            selectableRowsHighlight // Highlight selected rows if needed
                            paginationComponentOptions={{
                                rowsPerPageText: 'Rows per page:',
                                rangeSeparatorText: 'of',
                            }}
                            /> */}

                                    < DataTable
                                        columns={columns}
                                        data={paginatedData}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={tableData.length}
                                        paginationPerPage={perPage}
                                        onChangePage={handlePageChange}
                                        //progressPending={loading}
                                        onChangeRowsPerPage={handlePerPageChange}
                                        subHeader
                                        subHeaderComponent={
                                            <div class="col-md-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    class="form-control"
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                            </div>
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Premium;