import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowUpRight, Box, Calendar, CheckCircle, Home, Lock, MessageSquare, MinusCircle, Search } from 'react-feather';

const Header = () => {

    const history = useHistory();

    useEffect(() => {
        const currentTimestamp = Math.floor(Date.now() / 1000); // Divide by 1000 to get seconds
        const storedTimestamp = localStorage.getItem('time'); // Get the stored timestamp
        // console.log(currentTimestamp + "--" + storedTimestamp)
        if (currentTimestamp >= storedTimestamp) {
            // Clear localStorage and redirect to the login page
            localStorage.removeItem('time');
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            setTimeout(() => history.push("/"), 2000);

        }

        const sess = localStorage.getItem('token');
        if (!sess) {
            setTimeout(() => history.push("/"), 2000);
        }
    }, []);


    const LOGOUT = () => {
        localStorage.setItem('user', "")
        localStorage.setItem('token', "")
        localStorage.setItem('time', "")

        setTimeout(() => history.push("/"), 2000);
    }

    const addCss = () => {
        const navbar = document.querySelector(".navbar");
        if (navbar) {
            navbar.classList.add("dfg");
        }
    }
    return (
        <>
            <div class="main-wrapper">


                <nav class="sidebar">
                    <div class="sidebar-header">
                        <a href="#" class="sidebar-brand">
                            <img src="assets/images/12.png" style={{ height: "39px", width: "39px" }} /> WeighMall<span></span>
                        </a>
                        <div class="sidebar-toggler not-active" onClick={addCss}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="sidebar-body">
                        <ul class="nav">
                            {/* <li class="nav-item nav-category">Main</li> */}
                            <li class="nav-item">
                                <Link to={"/company"} class="nav-link">
                                    <Home size={18} />
                                    <span class="link-title">Company</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to={"/category"} class="nav-link">
                                    <MessageSquare size={18} />
                                    <span class="link-title">Categories</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to={"/approval"} class="nav-link">
                                    <Calendar size={18} />
                                    <span class="link-title">Review Company </span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to={"/premium"} class="nav-link">
                                    <Lock size={18} />
                                    <span class="link-title">Premium Company</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to={"/subscription"} class="nav-link">
                                    <CheckCircle size={18} />
                                    <span class="link-title">Subscriptions</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to={"/blacklist"} class="nav-link">
                                    <MinusCircle size={18} />
                                    <span class="link-title">Blacklist Company</span>
                                </Link>
                            </li>

                        </ul>
                    </div>
                </nav>

                {/* <div class="page-wrapper"> */}

                <nav class="navbar">
                    <a href="#" class="sidebar-toggler">
                        <i data-feather="menu"></i>
                    </a>
                    <div class="navbar-content">

                        <ul class="navbar-nav">

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img class="wd-30 ht-30 rounded-circle" src="assets/images/user.png" alt="profile" />
                                </a>
                                <div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
                                    <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
                                        <div class="mb-3">
                                            <img class="wd-80 ht-80 rounded-circle" src="assets/images/user.png" alt="loading" />
                                        </div>
                                        <div class="text-center">
                                            <p class="tx-16 fw-bolder">Admin</p>
                                        </div>
                                    </div>
                                    <ul class="list-unstyled p-1">
                                        <li class="dropdown-item py-2">
                                            <p class="text-body ms-0" >
                                                <i class="me-2 icon-md" data-feather="log-out" ></i>
                                                <span onClick={LOGOUT}>Log Out</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
                {/* </div> */}
            </div>
        </>
    );
}

export default Header;