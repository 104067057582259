

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "./config";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Login = () => {
  // window.location.reload(false);

  const initalState = {
    mobile: "", password: ""
  }

  const [state, setState] = useState(initalState)
  const { mobile, password } = state;

  const history = useHistory();
  useEffect(() => {
    const sess = localStorage.getItem('token');
    if (sess) {
      setTimeout(() => history.push("/company"), 2000);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const dObject = { authorization: "", input: { "username": mobile, "password": password } };

    const encodedData = btoa(JSON.stringify(dObject));
    const requestData = { "data": encodedData }
    console.log(state)
    if (!mobile) {
      toast.error('Enter Username');
    } else if (!password) {
      toast.error('Enter Password');
    } else {
      axios
        .post(BASE_URL + "/login.php",
          requestData
        )
        .then(function (data) {
          console.log(data)
          if (data.data.success == false) {
            toast.error('Username or password incorrect');
          } else {
            setState({
              mobile: '',
              password: ''
            });
            localStorage.setItem('user', data.data.data.username)
            localStorage.setItem('token', data.data.data.token)
            localStorage.setItem('time', data.data.data.eat)
            localStorage.setItem('id', data.data.data.id)
            toast.success("Logged In Successfully")
            // window.location.reload();
            setTimeout(() => history.push("/company"), 2000);

          }

        })
        .catch(error => console.error(error))
    }


  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };


  return (
    <>



      <div class="main-wrapper">
        <div class="page-wrapper full-page">
          <div class="page-content d-flex align-items-center justify-content-center">
            <ToastContainer />

            <div class="row w-100 mx-0 auth-page">
              <div class="col-md-8 col-xl-6 mx-auto">
                <div class="card">
                  <div class="row">
                    <div class="col-md-4 pe-md-0">
                      <div class="auth-side-wrapper">

                      </div>
                    </div>
                    <div class="col-md-8 ps-md-0">
                      <div class="auth-form-wrapper px-4 py-5">
                        <a href="#" class="noble-ui-logo d-block mb-2"><img src="assets/images/12.png" style={{ height: "39px", width: "39px" }} /> WeighMall</a>
                        <h5 class="text-muted fw-normal mb-4">Welcome back! Log in to your account.</h5>
                        <form class="forms-sample" onSubmit={e => handleSubmit(e)}>
                          <div class="mb-3">
                            <label for="userEmail" class="form-label">UserId</label>
                            <input name="mobile" onChange={changeHandler} class="form-control" type="text" placeholder="Mobile Number" required />
                          </div>
                          <div class="mb-3">
                            <label for="userPassword" class="form-label">Password</label>
                            <input name="password" onChange={changeHandler} placeholder="Password" class="form-control" type="password" required />
                          </div>

                          <div>
                            <button class="btn btn-primary me-2 mb-2 mb-md-0 text-white" name="submit">
                              Login Now
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default Login