import React, { useState } from "react";
import { useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "./config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';

const Category = () => {
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [ids, setIds] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [editShow, setEditShow] = useState(false);

    const [state, setState] = useState({
        names: "", logoUrl: "", eid: ""
    })
    const filteredData = searchText
        ? tableData.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
        )
        : tableData;



    const paginatedData = filteredData.slice((currentPage - 1) * perPage, currentPage * perPage);

    // Wrap the definition of 'abc' in useCallback
    const history = useHistory();
    const abc = useCallback(() => {
        if (typeof localStorage !== 'undefined') {
            const sess = localStorage.getItem('token');
            if (!sess) {
                history.push('/');
            }
        }

        if (typeof localStorage !== 'undefined') {
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: {} };
            const encodedData = btoa(JSON.stringify(dObject));
            const requestData = { "data": encodedData };
            axios
                .post(BASE_URL + "tags/list.php", requestData)
                .then(function (data) {
                    console.log(data);
                    if (data.data.success === true) {
                        setTableData(data.data.data.categories);
                        setIsLoading(false);

                    }
                })
                .catch(error => console.error(error));
        }
    }, [setTableData]); // Include router and setTableData in the dependency array

    useEffect(() => {
        abc();
    }, [abc]); // Use 'abc' as the dependency for useEffect

    const handleEdit = (id) => {

        setState({
            ...state, names: id.name,
            logoUrl: id.logoUrl,
            eid: id.id
        });
        setEditShow(true)

    }

    const RemoveValue = () => {

        if (state.eid != "") {
            if (typeof localStorage !== 'undefined') {
                // Access localStorage here
                const token = localStorage.getItem('token');
                const dObject = { authorization: token, input: { "id": state.eid, "name": state.names, "logoUrl": state.logoUrl } };
                const encodedData = btoa(JSON.stringify(dObject));
                const requestData1 = { "data": encodedData }
                axios
                    .post(BASE_URL + "tags/edit.php", requestData1)
                    .then(function (data) {
                        console.log(data)
                        if (data.data.success == true) {
                            toast.success("Category edited successfully");
                            setState({ eid: "", names: "", logoUrl: "" })
                            setEditShow(false)

                            abc()
                        } else {
                            toast.error("Category Not Edited")
                            setEditShow(false)

                            setState({ eid: "", names: "", logoUrl: "" })
                        }
                    });
            }
        } else {
            if (typeof localStorage !== 'undefined') {
                console.log("add")
                const token = localStorage.getItem('token');
                const dObject = { authorization: token, input: { "name": state.names, "logoUrl": state.logoUrl } };
                const encodedData = btoa(JSON.stringify(dObject));
                const requestData = { "data": encodedData }
                axios
                    .post(BASE_URL + "tags/create.php", requestData)
                    .then(function (data) {
                        console.log(data)
                        if (data.data.success == true) {
                            toast.success("Category Added Successfully");
                            setState({ eid: "", names: "", logoUrl: "" })
                            setEditShow(false)
                            abc();
                        } else {
                            setEditShow(false)
                            toast.error("Category Not Added");
                            setState({ eid: "", names: "", logoUrl: "" })
                        }
                    });
            }
        }
    }

    const handleCloseClick = (e) => {
        e.preventDefault();  // Prevent the default form submission behavior
        setState({ eid: "", names: "", logoUrl: "" })
        setEditShow(false)

    };

    const columns = [
        { name: 'Name', selector: (row) => row.name, sortable: true },
        { name: 'Image', selector: (row) => <img src={row.logoUrl} style={{ height: "40px", width: "40px" }} />, sortable: true },
        { name: 'EDIT', cell: (row) => <img src="assets/images/edit.png" style={{ height: "24px", width: "24px" }} alt="Bin" onClick={() => handleEdit(row)} />, sortable: true },
    ];
    const handler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handlePageChange = (page) => {
        setLoading(true);
        setCurrentPage(page);
    };


    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };


    return (
        <div>
            <div class="main-wrapper">

                <div class="page-wrapper">

                    <div class="page-content">
                        <ToastContainer />
                        <nav class="page-breadcrumb row">
                            <div class="col-md-10">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">Category</li>
                                    {/* <li class="breadcrumb-item active" aria-current="page">Basic Tables</li> */}
                                </ol>
                            </div>
                            <div class="col-md-2">
                                <button type='button' class="btn btn-primary" style={{ float: "right" }} onClick={() => setEditShow(true)}>Add Category</button>
                            </div>
                        </nav>
                        <div class="card">
                            <div class="card-body">
                                <div
                                    className={`modal fade  ${editShow ? 'show' : ''}`}
                                    tabIndex="-1"
                                    style={{ display: editShow ? 'block' : 'none', backgroundColor: "#21211f4a" }}
                                    aria-labelledby="exampleModalLabel"
                                    role={editShow ? "dialog" : ""}
                                    aria-hidden={!editShow}
                                    data-bs-backdrop="static"
                                    aria-modal={editShow ? true : false}
                                >
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Category</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button> */}
                                            </div>
                                            <div class="modal-body">
                                                <input id="name" class="form-control" name="eid" hidden onChange={handler} value={state.eid} type="text" />

                                                <div class="mb-3">
                                                    <label for="name" class="form-label">Name</label>
                                                    <input id="name" class="form-control" name="names" onChange={handler} value={state.names} type="text" />
                                                </div>


                                                <div class="mb-3">
                                                    <label for="password" class="form-label">Image</label>
                                                    <input id="password" class="form-control" name="logoUrl" onChange={handler} value={state.logoUrl} type="text" />
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary" onClick={() => RemoveValue(ids)}>{state.eid ? "Update" : "Submit"}</button>
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={(e) => handleCloseClick(e)}>Close</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">


                                    {/* <DataTable
                                title="Category Data"
                                columns={columns}
                                data={tableData}
                                pagination
                                paginationServer 
                            // paginationTotalRows={1}
                            // paginationPerPage={12}
                            // onChangePage={handlePageChange}
                            //progressPending={loading}
                            // selectableRows nable row selection if needed
                            // selectableRowsHighlight // Highlight selected rows if needed
                            // paginationComponentOptions={{
                            //     rowsPerPageText: 'Rows per page:',
                            //     rangeSeparatorText: 'of',
                            // }}
                            />*/}

                                    < DataTable
                                        columns={columns}
                                        data={paginatedData}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={tableData.length}
                                        paginationPerPage={perPage}
                                        onChangePage={handlePageChange}
                                        // progressPending={loading}
                                        onChangeRowsPerPage={handlePerPageChange}
                                        subHeader
                                        subHeaderComponent={
                                            <div class="col-md-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    class="form-control"
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                            </div>
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category;