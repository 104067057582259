import React, { useState } from "react";
import { useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "./config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';

const Blacklist = () => {
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({ names: "", phone: "", remark: "" })
    const [ids, setIds] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [editShow, setEditShow] = useState(false)

    const { names, phone, remark } = state

    // Wrap the definition of 'abc' in useCallback
    const history = useHistory();
    const abc = useCallback(() => {


        if (typeof localStorage !== 'undefined') {
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: {} };
            const encodedData = btoa(JSON.stringify(dObject));
            const requestData = { "data": encodedData };
            axios
                .post(BASE_URL + "company/black_list.php", requestData)
                .then(function (data) {
                    console.log(data);
                    if (data.data.success === true) {
                        setTableData(data.data.data.categories);
                        setIsLoading(false);

                    }
                })
                .catch(error => console.error(error));
        }
    }, [setTableData]); // Include router and setTableData in the dependency array

    useEffect(() => {
        const sess = localStorage.getItem('token');
        if (!sess) {
            history.push('/');
        }
        abc();
    }, [abc]); // Use 'abc' as the dependency for useEffect


    const handleEdit = (id) => {
        setState({
            ...state, id: id.id,
            names: id.name,
            phone: id.phone,
            remark: id.remark,
            status: "edit"
        });
        setEditShow(true)
    }


    const handleDelete = (id) => {
        setState({
            ...state,
            id: id.id,
            status: "delete"
        });
        setEditShow(true)

    }

    const ADD = (e) => {
        console.log(state)
        e.preventDefault();
        if (typeof localStorage !== 'undefined') {
            if (state.status == "delete") {

                const token = localStorage.getItem('token');
                const dObject = { authorization: token, input: { "id": state.id } };
                const encodedData = btoa(JSON.stringify(dObject));
                const deleteData = { "data": encodedData }
                axios
                    .post(BASE_URL + "company/black_delete.php", deleteData)
                    .then(function (data) {
                        if (data.data.success === true) {
                            toast.success("Blacklist Company deleted successfully")
                            setState({ status: "", id: "" })
                            setEditShow(false)
                            abc();
                        } else {
                            toast.error("company Not deleted")
                            setEditShow(false)
                            setState({ status: "" })

                        }
                    }
                    )



            } else if (state.status == "edit") {
                const token = localStorage.getItem('token');
                const dObject = { authorization: token, input: { "id": state.id, "name": names, "phone": phone, "remark": remark } };
                const encodedData = btoa(JSON.stringify(dObject));
                const requestData1 = { "data": encodedData }
                axios
                    .post(BASE_URL + "company/black_edit.php", requestData1)
                    .then(function (data) {
                        console.log(data)
                        if (data.data.success == true) {
                            toast.success("Blacklist edited successfully");
                            setState({ status: "", names: "", phone: "", remark: "", id: "" })
                            setEditShow(false)

                            abc()
                        } else {
                            toast.error("Blacklist Not Edited")
                            setState({ status: "" })
                            setEditShow(false)

                        }
                    });
            } else {

                const token = localStorage.getItem('token');
                const dObject = { authorization: token, input: { "personName": names, "phone": phone, "remark": remark } };
                const encodedData = btoa(JSON.stringify(dObject));
                const requestData1 = { "data": encodedData }
                axios
                    .post(BASE_URL + "company/blacklist_create.php", requestData1)
                    .then(function (data) {
                        console.log(data)
                        if (data.data.success == true) {
                            toast.success("Blacklist Phone Number Added Successfully");
                            setState({ status: "", names: "", phone: "", remark: "" })
                            setEditShow(false)

                            abc();
                        } else if (data.data.message === "Company Already Registered") {
                            toast.error("Company already registered ,First you have to delete that");
                            abc();
                        } else if (data.data.message === "Mobile number is already blacklisted") {
                            toast.error("Mobile number is already blacklisted");
                            abc();
                        } else {
                            toast.error("Blacklist Phone Number Not Added");
                            setEditShow(false)
                            abc();
                        }
                    });
            }
        }
    }


    const handleCloseClick = (e) => {
        e.preventDefault();  // Prevent the default form submission behavior
        setState({ status: "", names: "", phone: "", remark: "", id: "" })
        setEditShow(false)


    };

    const [searchText, setSearchText] = useState('')
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })


    const filteredData = searchText
        ? tableData.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.phone.trim().toString().includes(searchText.toString())
        )
        : tableData;



    const paginatedData = filteredData.slice((currentPage - 1) * perPage, currentPage * perPage);

    const columns = [
        { name: 'Company / User Name',width:'380px', selector: (row) => row.name, sortable: true },
        { name: 'Phone No.', selector: (row) => row.phone,width:'150px', sortable: true },
        { name: 'Remark', selector: (row) => row.remark,width:'500px', sortable: true },
        { name: 'Edit',width:'80px', cell: (row) => <img src="assets/images/edit.png" style={{ height: "24px", width: "24px" }} alt="Bin" onClick={() => handleEdit(row)} />, sortable: true },
        { name: 'Delete', width:'100px',cell: (row) => <img src="assets/images/bin.png" style={{ height: "24px", width: "24px" }} alt="Bin" onClick={() => handleDelete(row)} />, sortable: true },
    ];
    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };
    const handlePageChange = (page) => {
        setLoading(true);
        setCurrentPage(page);
    };

    return (
        <div>
            <div class="main-wrapper" >

                <div class="page-wrapper" >

                    <div class="page-content" >
                        <ToastContainer />

                        <nav class="page-breadcrumb row">
                            <div class="col-md-10">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">Blacklist Company</li>
                                    {/* <li class="breadcrumb-item active" aria-current="page">Basic Tables</li> */}
                                </ol>
                            </div>
                            <div class="col-md-2">
                                <button type='button' class="btn btn-primary" style={{ float: "right" }} onClick={() => setEditShow(true)}>Add Blacklist Company</button>
                            </div>
                        </nav>
                        <div class="card">
                            <div class="card-body">
                                <div
                                    className={`modal fade  ${editShow ? 'show' : ''}`}
                                    tabIndex="-1"
                                    style={{ display: editShow ? 'block' : 'none', backgroundColor: "#21211f4a" }}
                                    aria-labelledby="exampleModalLabel"
                                    role={editShow ? "dialog" : ""}
                                    aria-hidden={!editShow}
                                    data-bs-backdrop="static"
                                    aria-modal={editShow ? true : false}
                                >
                                    {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"> */}
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Blacklist Company</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button> */}
                                            </div>
                                            <div class="modal-body">

                                                {state.status === "delete" ? (
                                                    <>
                                                        <div>
                                                            Are You Sure ?
                                                        </div>

                                                    </>
                                                ) : (
                                                    <>
                                                        <div>
                                                            <div class="mb-3">
                                                                <p>Company Name</p>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={state.names}
                                                                    placeholder="Name"
                                                                    onChange={(e) => setState({ ...state, names: e.target.value })}
                                                                />
                                                            </div>
                                                            <div class="mb-3">
                                                                <p>Phone Number</p>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={state.phone}
                                                                    placeholder="4845646545"
                                                                    onChange={(e) => setState({ ...state, phone: e.target.value })}
                                                                />
                                                            </div>
                                                            <div class="mb-3">
                                                                <p>Remarks</p>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={state.remark}
                                                                    placeholder="Remarks"
                                                                    onChange={(e) => setState({ ...state, remark: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary" onClick={(e) => ADD(e)}>{state.status == "delete" ? "Delete" : "Submit"}</button>
                                                <button type="button" class="btn btn-danger" onClick={(e) => handleCloseClick(e)}>Close</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive" >
                                    {/* <DataTable
                                title="Blacklist Companies"
                                columns={columns}
                                data={tableData}
                                pagination
                                paginationServer 
                            // paginationTotalRows={1}
                            // paginationPerPage={12}
                            // onChangePage={handlePageChange}
                      //progressPending={loading}
      subHeader
      subHeaderComponent={
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      }                           // selectableRows nable row selection if needed
                            // selectableRowsHighlight // Highlight selected rows if needed
                            // paginationComponentOptions={{
                            //     rowsPerPageText: 'Rows per page:',
                            //     rangeSeparatorText: 'of',
                            // }}
                            // />*/}

                                    < DataTable
                                        columns={columns}
                                        data={paginatedData}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={tableData.length}
                                        paginationPerPage={perPage}
                                        onChangePage={handlePageChange}
                                        //progressPending={loading}
                                        onChangeRowsPerPage={handlePerPageChange}
                                        subHeader
                                        subHeaderComponent={
                                            <div class="col-md-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    class="form-control"
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                            </div>
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blacklist;