import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Pages/header";
import Login from "./Pages/Login";
import Footer from "./Pages/footer";
import ScrollToTop from "./Pages/ScrolToTop";
import Company from "./Pages/company";
import Category from "./Pages/category";
import Approval from "./Pages/approval";
import Premium from "./Pages/premium";
import Subscription from "./Pages/order";
import Blacklist from "./Pages/blacklist";

function App() {
  // Check if the current route is the login page
  // const isLoginPage = window.location.pathname === '/';
  // console.log(isLoginPage)

  return (
    <Router>
      <div>


        <ScrollToTop />
        <Switch>

          <Route exact path="/"><Login /></Route>
          <Route path="/company" ><Header /><Company /><Footer /></Route>
          <Route path="/category" ><Header /><Category /><Footer /></Route>
          <Route path="/approval" ><Header /><Approval /><Footer /></Route>
          <Route path="/premium" ><Header /><Premium /><Footer /></Route>
          <Route path="/subscription" ><Header /><Subscription /><Footer /></Route>
          <Route path="/blacklist" ><Header /><Blacklist /><Footer /></Route>
        </Switch>


      </div>
    </Router>
  );
}

export default App;
