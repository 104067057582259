import React from "react";

const Footer = () => {
    return (
        <>
            <div class="main-wrapper">
                <div class="page-wrapper">

                    <div class="page-content">

                        <footer class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
                            <p class="text-muted mb-1 mb-md-0">© 2023, Made with  <a href="https://www.gurusoftware.in/" target="_blank">❤️ by GuruSoftware</a>.</p>
                            <p class="text-muted"><a href="https://www.gurusoftware.in/" target="_blank">Contact Us </a></p>
                        </footer>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Footer;