import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { BASE_URL } from "./config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Company = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [colorOptions, setColorOptions] = useState([]);
  const [selectedCate, setSelectedCate] = useState("");
  const [eids, setEids] = useState("");
  const [editShow, setEditShow] = useState(false);
  const [error, setError] = useState("");
  const [alterror, setAlterror] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [stateName, setStateName] = useState("");
  const [ids, setIds] = useState("");
  const [delShow, setDelShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const [state, setState] = useState({
    cname: "",
    citys: "",
    location: "",
    phone: "",
    gst: "",
    pname: "",
    email: "",
    credit: "",
    states: "",
    scode: "",
    altphone: "",
    cat: [],
  });

  const getState = (e) => {
    const stateval = e.target.value;
    // If you also need to update stateName, you can find the corresponding label in optionsData
    const selectedOption = optionsData.find(
      (option) => option.value === stateval
    );
    if (selectedOption) {
      setStateCode(selectedOption.value);
      setStateName(selectedOption.label);
    }
  };

  const {
    cname,
    citys,
    location,
    states,
    scode,
    cat,
    phone,
    credit,
    gst,
    pname,
    email,
    altphone,
    checkboxes,
    eid,
  } = state;

  let name, value;

  const handler = (e) => {
    const { name, value } = e.target;
    const regex = /^\+?\d{10,15}$/;

    // const numberonly = regex.test(value);
    // if (numberonly) {
    //     setError("Only Numbers allowed")
    // } else {

    if (name === "phone" || name === "altphone") {
      const hasSpaces = /\s/.test(value);

      if (hasSpaces) {
        if (name === "phone") {
          setError("Phone Number has a spaces.");
        } else {
          setAlterror("Phone Number has a spaces.");
        }
      } else {
        const trimmedValue = value.trim();
        setError("");
        if (
          eid != null &&
          eid != "" &&
          (name === "altphone" || name === "phone")
        ) {
          if (trimmedValue.length <= 13) {
            setState({ ...state, [name]: value });
            if (name === "phone") {
              setError("Phone Number must be 13 digits.");
            } else {
              setAlterror("Phone Number must be 13 digits.");
            }
          } else {
            if (name === "phone") {
              setError("");
            } else {
              setAlterror("");
            }
          }
        } else if (name === "phone" || name === "altphone") {
          if (trimmedValue.length <= 10) {
            setState({ ...state, [name]: value });
            if (name === "phone") {
              setError("Phone Number must be 10 digits.");
            } else {
              setAlterror("Phone Number must be 10 digits.");
            }
          } else {
            if (name === "phone") {
              setError("");
            } else {
              setAlterror("");
            }
          }
        }
      }
    } else {
      setError("");
      setState({ ...state, [name]: value });
    }
    // }
  };

  const optionsData = [
    { value: "", label: "All States" },
    { value: "1", label: "Andhra Pradesh" },
    { value: "2", label: "Arunachal Pradesh" },
    { value: "3", label: "Assam" },
    { value: "4", label: "Bihar" },
    { value: "5", label: "Chhattisgarh" },
    { value: "6", label: "Goa" },
    { value: "7", label: "Gujarat" },
    { value: "8", label: "Haryana" },
    { value: "9", label: "Himachal Pradesh" },
    { value: "10", label: "Jharkhand" },
    { value: "11", label: "Karnataka" },
    { value: "12", label: "Kerala" },
    { value: "13", label: "Madhya Pradesh" },
    { value: "14", label: "Maharashtra" },
    { value: "15", label: "Manipur" },
    { value: "16", label: "Meghalaya" },
    { value: "17", label: "Mizoram" },
    { value: "18", label: "Nagaland" },
    { value: "19", label: "Odisha" },
    { value: "20", label: "Punjab" },
    { value: "21", label: "Rajasthan" },
    { value: "22", label: "Sikkim" },
    { value: "23", label: "Tamil Nadu" },
    { value: "24", label: "Telangana" },
    { value: "25", label: "Tripura" },
    { value: "26", label: "Uttarakhand" },
    { value: "27", label: "Uttar Pradesh" },
    { value: "28", label: "West Bengal" },
    { value: "29", label: "Andaman and Nicobar Islands" },
    { value: "30", label: "Chandigarh" },
    { value: "31", label: "Dadra and Nagar Haveli" },
    { value: "32", label: "Daman and Diu" },
    { value: "33", label: "Delhi" },
    { value: "34", label: "Lakshadweep" },
    { value: "35", label: "Puducherry" },
    { value: "36", label: "Jammu and Kashmir" },
    { value: "37", label: "Ladakh" },
  ];

  const fetchData = async (
    page,
    search,
    pageSize,
    selectedState,
    selectedCate
  ) => {
    try {
      setIsLoading(true);
      if (typeof localStorage !== "undefined") {
        const tokens = localStorage.getItem("token");
        const dObject = {
          authorization: tokens,
          input: {
            page: page,
            pageSize: pageSize,
            searchText: search,
            state: selectedState,
            category: parseInt(selectedCate),
          },
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const requestData = { data: encodedData };

        const response = await fetch(BASE_URL + "company/getList.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        const result = await response.json();
        console.log(result.data);
        setData(result.data.companies);
        setTotalRows(result.data.totalPages);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const cdata = () => {
    if (typeof localStorage !== "undefined") {
      const tokens = localStorage.getItem("token");
      const dObject = { authorization: tokens, input: {} };
      const encodedData = btoa(JSON.stringify(dObject));
      const requestData = { data: encodedData };
      axios
        .post(BASE_URL + "tags/list.php", requestData)
        .then((data) => {
          setColorOptions(data.data.data.categories);
        })
        .catch((error) => console.error(error));
    }
  };

  const history = useHistory();
  useEffect(() => {
    const sess = localStorage.getItem("token");
    if (!sess) {
      history.push("/");
    }
    cdata();
    fetchData(currentPage, searchText, pageSize, selectedState, selectedCate);
  }, [currentPage, searchText, pageSize, selectedState, selectedCate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  
  const columns = [
    { name: "Credit", selector: (row) => row.credit, sortable: true, width: '90px' },
    { name: "Admin Name", selector: (row) => row.aname, sortable: true, width: '120px' },
    { name: "Name", selector: (row) => row.name, width: '150px', sortable: true },
    { name: "PersonName", selector: (row) => row.personName, width: '150px', sortable: true },
    {
      name: "Category",
      selector: (row) => row.tagName.join(", "),
      width: '150px',
      sortable: true,
    },
    { name: "State", selector: (row) => row.userState, width: '150px', sortable: true },
    { name: "City", selector: (row) => row.city, width: '150px', sortable: true },
    { name: "Location", selector: (row) => row.location, width: '150px', sortable: true },
    { name: "Phone Number", selector: (row) => row.phone, width: '150px', sortable: true },
    {
      name: "Alternate Number",
      selector: (row) => row.altphone,
      sortable: true,
      width: '150px',
    },
    { name: "GST", selector: (row) => row.gst, width: '150px', sortable: true },
    { name: "Email", selector: (row) => row.email, width: '250px', sortable: true },
    {
      name: "Created Date",
      selector: (row) => row.date ? formatDate(row.date.trim()) : '',
      width: '140px',
      sortable: true
    },
    {
      name: "EDIT",
      width: '80px',
      cell: (row) => (
        <img
          src="assets/images/edit.png"
          style={{ height: "24px", width: "24px" }}
          alt="Edit"
          onClick={() => handleEdit(row)}
        />
      ),
      sortable: true,
    },
    {
      name: "DELETE",
      width: '80px',
      cell: (row) => (
        <img
          src="assets/images/bin.png"
          style={{ height: "24px", width: "24px" }}
          alt="Delete"
          onClick={() => deleteRow(row)}
        />
      ),
      sortable: true,
    },
  ];
  
  const handleCheckboxChange = (event) => {
    const checkboxId = event.target.name; // Assuming the name attribute is set to the option ID
    const isChecked = event.target.checked;

    // Update the state based on the checkbox change
    if (isChecked) {
      // If checked, add the checkbox ID to the state.checkboxes array
      setState((prevState) => ({
        ...prevState,
        cat: [...(prevState.cat || []), checkboxId],
      }));
    } else {
      // If unchecked, remove the checkbox ID from the state.checkboxes array
      setState((prevState) => ({
        ...prevState,
        cat: (prevState.cat || []).filter((id) => id !== checkboxId),
      }));
    }
  };

  const handleEdit = (id) => {
    setEditShow(true);
    console.log(id);
    setState({
      ...state,
      cname: id.name,
      cat: id.tagId,
      catname: id.tags,
      pname: id.personName,
      citys: id.city,
      location: id.location,
      altphone: id.altphone,
      credit: id.credit,
      aid:localStorage.getItem('aid'),
      gst: id.gst,
      phone: id.phone,
      email: id.email,
      checkboxes: id.checkboxes,
      eid: id.id,
    });
    setStateCode(id.stateCode);
    setStateName(id.userState);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    setCurrentPage(1); // Reset page to 1 when search text changes
  };

  const handleStateChange = (e) => {
    const selectedValue = e.target.value;
    console.log(e.target.value);
    setSelectedState(selectedValue);
    setCurrentPage(1); // Reset page to 1 when state changes
  };
  const handleCateChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCate(selectedValue);
    setCurrentPage(1); // Reset page to 1 when state changes
  };

  const validatePhoneNumber = () => {
    const regex = /^\+?\d{10,15}$/; // Regular expression for phone number validation
    if (!regex.test(phone)) {
      return false;
    }

    return true;
  };

  const deleteRow = (id) => {
    setIds(id.id);
    setDelShow(true);
  };

  const handleCloseClick = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setEditShow(false);
    setState({
      eid: "",
      cname: "",
      citys: "",
      location: "",
      credit: "",
      phone: "",
      gst: "",
      pname: "",
      email: "",
      cat: [],
      altphone: "",
    });
    setStateCode("");
    setStateName("");
  };

  const RemoveValue = () => {
    if (typeof localStorage !== "undefined") {
      const token = localStorage.getItem("token");
      const dObject = { authorization: token, input: { id: ids } };
      const encodedData = btoa(JSON.stringify(dObject));
      const deleteData = { data: encodedData };
      axios
        .post(BASE_URL + "company/delete.php", deleteData)
        .then(function (data) {
          if (data.data.success === true) {
            toast.success("company deleted successfully");
            setDelShow(false);
            setAlterror("");
            setError("");
            fetchData(
              currentPage,
              searchText,
              pageSize,
              selectedState,
              selectedCate
            );
          } else {
            toast.error("company Not deleted");
            setDelShow(false);
            setAlterror("");
            setError("");
          }
        });
    }
  };
  const isAtLeastOneCheckboxChecked = state.cat.length > 0;
  console.log(localStorage.getItem("id"));

  const handle = (e) => {
    // sessionData();
    const regex = /^\+?\d{10,15}$/; // Regular expression for phone number validation
    console.log("enter" + eid);
    e.preventDefault();

    if (eid != null && eid != "") {
      if (typeof localStorage !== "undefined") {
        const token = localStorage.getItem("token");
        const dObject = {
          authorization: token,
          input: {
            id: eid,
            companyName: cname,
            city: citys,
            location: location,
            credit: credit,
            stateCode: stateCode,
            stateName: stateName,
            phone: phone.trim(),
            gst: gst,
            aid:localStorage.getItem('id'),
            personName: pname,
            email: email,
            tags: state.cat,
            altphone: altphone,
          },
        };
        const encodedData = btoa(JSON.stringify(dObject));
        const requestData = { data: encodedData };
        axios
          .post(BASE_URL + "company/edit.php", requestData)
          .then(function (data) {
            console.log(data);
            if (data.data.success == true) {
              toast.success("Company edited successfully");
              setState({
                eid: "",
                cname: "",
                citys: "",
                location: "",
                credit: "",
                phone: "",
                gst: "",
                pname: "",
                email: "",
                cat: [],
                altphone: "",
              });
              setEditShow(false);
              setStateCode("");
              setStateName("");
              setAlterror("");
              setError("");
              fetchData(
                currentPage,
                searchText,
                pageSize,
                selectedState,
                selectedCate
              );
            } else if (
              data.data.success == false &&
              data.data.message == "Same Mobile Numbers"
            ) {
              toast.error("Phone Number Already registered");
            } else {
              toast.error("Company Not Edited");
              setState({
                eid: "",
                cname: "",
                citys: "",
                location: "",
                credit: "",
                phone: "",
                gst: "",
                pname: "",
                email: "",
                cat: [],
                altphone: "",
              });
              setStateCode("");
              setEditShow(false);
              setStateName("");
              setAlterror("");
              setError("");
            }
          });
      }
    } else {
      if (phone === null || phone === "") {
        toast.error("Please enter Mobile Number");
      } else if (!validatePhoneNumber) {
        toast.error("Mobile number is incorrect");
      } else if (stateCode === null || stateCode === "") {
        toast.error("Please enter Company State");
      } else  if (!isAtLeastOneCheckboxChecked) {
        toast.error("Please Select Category");
      } else{
        setLoading(true);
        console.log(cat);

        if (typeof localStorage !== "undefined") {
          const tokens = localStorage.getItem("token");
          const dObject = {
            authorization: tokens,
            input: {
              companyName: cname,
              city: citys,
              location: location,
              credit: credit,
              stateCode: stateCode,
              state: stateName,
              phone: phone.trim(),
              gst: gst,
              aid:localStorage.getItem('id'),
              personName: pname,
              email: email,
              tags: cat,
              altphone: altphone,
            },
          };
          const encodedData = btoa(JSON.stringify(dObject));
          const requestData = { data: encodedData };
          axios
            .post(BASE_URL + "company/create.php", requestData)
            .then(function (data) {
              console.log(data);
              if (data.data.success == true) {
                setState({
                  cname: "",
                  city: "",
                  location: "",
                  phone: "",
                  gst: "",
                  pname: "",
                  credit: "",
                  email: "",
                  cat: [],
                  altphone: "",
                });
                setStateCode("");
                setStateName("");
                setEditShow(false);
                setAlterror("");
                setError("");
                toast.success("Company Added Successfully");
                fetchData(
                  currentPage,
                  searchText,
                  pageSize,
                  selectedState,
                  selectedCate
                );
              } else if (
                data.data.success == false &&
                data.data.message == "Mobile number is same"
              ) {
                toast.error("Mobile Number Already Exist");
                setIsLoading(false);
              } else if (
                data.data.success == false &&
                data.data.message == "Mobile In Blacklist so you can not add"
              ) {
                toast.error("Phone Number In Blacklist");
                setIsLoading(false);
              } else {
                toast.error("Company Not Added");
                setStateCode("");
                setStateName("");
                setEditShow(false);
                setAlterror("");
                setError("");
              }
            });
          setLoading(false);
        }
      }
    }
  };

  const addComp = () => {
    setEditShow(true);
  };

  const handlePerPageChange = async (newPageSize, page) => {
    setPageSize(newPageSize);
    setCurrentPage(page);
    await fetchData(
      currentPage,
      searchText,
      pageSize,
      selectedState,
      selectedCate
    );
  };

  return (
    <div>
      <div class="main-wrapper">
        <div class="page-wrapper">
          <div class="page-content">
            <ToastContainer />
            <nav class="page-breadcrumb row">
              <div class="col-md-10">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">Companies</li>
                </ol>
              </div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{ float: "right" }}
                  onClick={addComp}
                >
                  Add Company
                </button>
              </div>
            </nav>

            <div class="row">
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-2">
                        <select
                          className="form-select"
                          id="exampleFormControlSelect1"
                          onChange={handleStateChange}
                          value={selectedState}
                        >
                          <option selected disabled>
                            Select state
                          </option>
                          {optionsData.map((option) => (
                            <option key={option.value} value={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="col-md-2">
                        <select
                          className="form-select"
                          id="exampleFormControlSelect1"
                          onChange={handleCateChange}
                          value={selectedCate}
                        >
                          <option value={""}>All Category</option>
                          {colorOptions.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="col-md-2">
                        <input
                          type="text"
                          value={searchText}
                          class="form-control"
                          onChange={handleSearchChange}
                          placeholder="Search..."
                        />
                      </div>
                    </div>

                    <div class="table-responsive">
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={pageSize}
                        onChangePage={handlePageChange}
                        // progressPending={loading}
                        onChangeRowsPerPage={handlePerPageChange}

                        // selectableRows nable row selection if needed
                        // selectableRowsHighlight // Highlight selected rows if needed
                        // paginationComponentOptions={{
                        //     rowsPerPageText: 'Rows per page:',
                        //     rangeSeparatorText: 'of',
                        // }}
                      />
                    </div>

                    <div
                      class={`modal fade ${delShow ? "show" : ""}`}
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      style={{
                        display: delShow ? "block" : "none",
                        backgroundColor: "#21211f4a",
                      }}
                      aria-modal={delShow ? true : false}
                      role={delShow ? "dialog" : ""}
                      aria-hidden={!delShow}
                      data-bs-backdrop="static"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Delete Company
                            </h5>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button> */}
                          </div>
                          <div class="modal-body">
                            <p style={{ textAlign: "center" }}>
                              Are You Sure ?
                            </p>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() => RemoveValue(ids)}
                            >
                              Delete
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={() => setDelShow(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`modal fade bd-example-modal-xl ${
                        editShow ? "show" : ""
                      }`}
                      tabIndex="-1"
                      style={{
                        display: editShow ? "block" : "none",
                        backgroundColor: "#21211f4a",
                      }}
                      aria-labelledby="myExtraLargeModalLabel"
                      role={editShow ? "dialog" : ""}
                      aria-hidden={!editShow}
                      data-bs-backdrop="static"
                      aria-modal={editShow ? true : false}
                    >
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5
                              class="modal-title"
                              style={{ textAlign: "center" }}
                              id="exampleModalLabel"
                            >
                              Company Details
                            </h5>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button> */}
                          </div>
                          <form
                            id="signupForm "
                            className="row"
                            onSubmit={(e) => handle(e)}
                          >
                            <div class="modal-body">
                              <div class="card-body row">
                                <div class="col-lg-6 ">
                                  <input
                                    id="name"
                                    class="form-control"
                                    name="eid"
                                    hidden
                                    onChange={handler}
                                    value={state.eid}
                                    type="text"
                                  />

                                  <div class="mb-3">
                                    <label for="name" class="form-label">
                                      Name (*)
                                    </label>
                                    <input
                                      id="name"
                                      class="form-control"
                                      name="cname"
                                      onChange={handler}
                                      value={state.cname}
                                      type="text"
                                    />
                                  </div>

                                  <div class="mb-3">
                                    <label for="password" class="form-label">
                                      Phone Number (*)
                                    </label>
                                    <input
                                      id="password"
                                      class="form-control"
                                      name="phone"
                                      onChange={handler}
                                      value={state.phone}
                                      type="text"
                                    />
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {error ? error : ""}
                                    </p>
                                  </div>
                                  <div class="mb-3">
                                    <label for="email" class="form-label">
                                      Email
                                    </label>
                                    <input
                                      id="email"
                                      class="form-control"
                                      name="email"
                                      onChange={handler}
                                      value={state.email}
                                      type="email"
                                    />
                                  </div>

                                  <div class="mb-3">
                                    <label for="password" class="form-label">
                                      City
                                    </label>
                                    <input
                                      id="password"
                                      class="form-control"
                                      name="citys"
                                      onChange={handler}
                                      value={state.citys}
                                      type="text"
                                    />
                                  </div>
                                  <div class="mb-3">
                                    <label for="email" class="form-label">
                                      GST
                                    </label>
                                    <input
                                      id="email"
                                      class="form-control"
                                      name="gst"
                                      onChange={handler}
                                      value={state.gst}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-6 ">
                                  <div class="mb-3">
                                    <label for="name" class="form-label">
                                      Person Name
                                    </label>
                                    <input
                                      id="name"
                                      class="form-control"
                                      name="pname"
                                      onChange={handler}
                                      value={state.pname}
                                      type="text"
                                    />
                                  </div>
                                  <div class="mb-3">
                                    <label
                                      for="confirm_password"
                                      class="form-label"
                                    >
                                      Alternate Phone Number
                                    </label>
                                    <input
                                      id="confirm_password"
                                      class="form-control"
                                      name="altphone"
                                      onChange={handler}
                                      value={state.altphone}
                                      type="text"
                                    />
                                    <p class="danger" style={{ color: "red" }}>
                                      {alterror ? alterror : ""}
                                    </p>
                                  </div>
                                  <div class="mb-3">
                                    <label for="ageSelect" class="form-label">
                                      State (*)
                                    </label>
                                    <select
                                      className="form-select"
                                      name="states"
                                      onChange={(e) => getState(e)}
                                      id="ageSelect"
                                      value={stateCode} // Set the value attribute to the state code directly
                                    >
                                      <option disabled>Select State</option>
                                      {optionsData.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div class="mb-3">
                                    <label
                                      for="confirm_password"
                                      class="form-label"
                                    >
                                      Location
                                    </label>
                                    <input
                                      id="confirm_password"
                                      class="form-control"
                                      name="location"
                                      onChange={handler}
                                      value={state.location}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div class="mb-3" style={{ margin: "10px" }}>
                                  <label class="form-label">
                                    Categories (*)
                                  </label>
                                  <div className="row">
                                    {colorOptions.map((option) => (
                                      <div
                                        className="col-auto form-check mb-2 mr-3"
                                        key={option.value}
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`check${option.id}`}
                                          checked={
                                            state.cat
                                              ? state.cat.includes(
                                                  option.id.toString()
                                                )
                                              : false
                                          }
                                          onChange={handleCheckboxChange}
                                          name={option.id.toString()}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`check${option.id}`}
                                        >
                                          {option.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                {/* {isAtLeastOneCheckboxChecked && (
                                                                    <div className="text-danger">Please select at least one category.</div>
                                                                )} */}
                                <div class="mb-3">
                                  <label
                                    for="confirm_password"
                                    class="form-label"
                                  >
                                    Credit
                                  </label>
                                  <input
                                    id="confirm_password"
                                    class="form-control"
                                    name="credit"
                                    onChange={handler}
                                    value={state.credit ? state.credit : "3"}
                                    type="text"
                                    readOnly={state.eid ? "" : "readOnly"}
                                  />
                                </div>
                              </div>
                              <div class="modal-footer">
                                <input
                                  class="btn btn-primary"
                                  style={{ alignContent: "center" }}
                                  type="submit"
                                  value={eid ? "Update" : "Submit"}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => handleCloseClick(e)}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
